<script setup>
import google_login from "@/assets/google-login.svg";
import { useUserStore, useSubscriptionInfo } from "@/store/user.js";
import { useRouter, useRoute } from "vue-router";
import {
  useTokenClient,
} from "vue3-google-signin";
const subscriptionInfo = useSubscriptionInfo()
const user = useUserStore();
const route = useRoute();
const router = useRouter();

const handleOnSuccess = async (response) => {


  if (response.access_token) {
    const res = await user.googleLogin({ accessToken: response.access_token })

    const { code, data } = res
    if (code === 0) {
      if (data.register) {
        // 首次注册，上报埋点
        gr('track', 'conversion', { email: data.email })
        window.fpr("referral", { email: data.email })
      }

      // user.userSubscription();
      user.subscribeState();
      await subscriptionInfo.refresh()

      const redirect = route.query?.redirect
      if (redirect) {
        router.push({ path: route.query.redirect });
      } else {
        router.push("/");
      }
    }
  }
};

const handleOnError = (errorResponse) => {
  console.log("Error: ", errorResponse);
};

const { isReady, login } = useTokenClient({
  onSuccess: handleOnSuccess,
  onError: handleOnError,
  // other options
});

</script>
<template>
  <div class="container-google-login">
    <a-button type="primary" block class="google-login-button" @click.stop="() => login()">
      <template #icon><img :src="google_login" /></template>
      Continue with Google
    </a-button>
    <div class="pt-5">
      <a-divider class="google-login-divider text-inc-color border-t-border-color my-8">or</a-divider>
    </div>
  </div>
</template>
<style lang="less" scoped>
.google-login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  border-radius: 36px;
  background: #060606;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #060606;
  color: #ffffff;
  margin: 0 auto;

  img {
    margin-right: 20px;
  }

  &:hover {
    background: #2f2f2f;
  }
}

.google-login-divider {
  border-top-color: #dee0e3;
  color: #8f959e;
}
</style>
