<script setup>
import { reactive, ref, watch, h } from "vue";
import GoogleLogin from "./google-login.vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
import prefix_name from "@/assets/user-name.svg";
import prefix_email from "@/assets/prefix_email.svg";
import prefix_password from "@/assets/prefix_password.svg";
import prefix_name_fail from "@/assets/fail_username.svg";
import prefix_email_fail from "@/assets/fail_email.svg";
import prefix_password_fail from "@/assets/fail_password.svg";
import { useDebounceFn } from "@vueuse/core";
import { checkForm } from "./utils.js";
import VerificationCode from "./verification-code.vue";
import { useIntervalFn } from "@vueuse/core";
import { useUserStore } from "@/store/user.js";

const user = useUserStore();

const formState = reactive({
  name: "",
  email: "",
  password: "",
});
const registerButtonDisabled = ref(true);

watch(formState, () => {
  /* 深层级变更状态所触发的回调 */
  if (formState.email && formState.name && formState.password) {
    registerButtonDisabled.value = false;
  } else {
    registerButtonDisabled.value = true
  }
})

const isLoading = ref(false)
const errorState = reactive({
  name: "success",
  email: "success",
  password: "success",
  errorMsg: "",
});

const step = ref(1);

//init errorState
const initErrorState = () => {
  errorState.name = "success";
  errorState.email = "success";
  errorState.password = "success";
  errorState.errorMsg = "";
};

const setErrorState = (msg) => {
  errorState.name = "error";
  errorState.email = "error";
  errorState.password = "error";
  errorState.errorMsg = msg;
};

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '20px',
    color: '#fff',
    marginRight: '8px'
  },
  spin: true,
});


const timer = ref(0);

const { pause, resume } = useIntervalFn(
  () => {
    if (timer.value <= 0) {
      // 停止定时任务
      pause();
    } else {
      // 单次定时任务执行的回调
      timer.value--;
    }
  },
  1000,
  {
    // 默认不开启定时任务
    immediate: false,
  }
);

const sendEmail = async () => {
  if (timer.value === 0) {
    isLoading.value = true
    const res = await user.sendEmail({ email: formState.email });
    isLoading.value = false
    const { code, msg } = res;
    if (code !== 0) {
      errorState.errorMsg = msg;
    } else {
      timer.value = 60;
      resume();
      step.value = 2;
    }
  } else {
    step.value = 2;
    return;
  }
};

const debouncedFn = useDebounceFn((value) => {
  console.log(value);
  //send code
  sendEmail();
}, 500);

const onFinish = (values) => {
  checkForm(values, false)
    .then(() => {
      initErrorState();
      debouncedFn(values);
    })
    .catch((errorInfo) => {
      console.log(errorInfo);
      errorState.errorMsg = errorInfo.message;
      errorState.name = errorInfo.errorFiles.includes("name")
        ? "error"
        : "success";
      errorState.email = errorInfo.errorFiles.includes("email")
        ? "error"
        : "success";
      errorState.password = errorInfo.errorFiles.includes("password")
        ? "error"
        : "success";
    });
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};


</script>

<template>
  <div>
    <slot></slot>
    <a-form v-if="step === 1" :model="formState"
      class="login-form flex-1 flex justify-center items-center flex-col" name="basic" autocomplete="off"
      @finish="onFinish" @finishFailed="onFinishFailed">
      <a-form-item class="mb-3">
        <h1 class="text-black text-2xl font-medium">Sign up</h1>
      </a-form-item>
      <a-form-item class="mb-4">
        <span class="text-gray-400">Already have an account?</span>
        <router-link to="/login" class="text-purple-500"> Log in </router-link>
      </a-form-item>
      <a-form-item class="mb-6">
        <GoogleLogin />
      </a-form-item>
      <a-form-item name="name" class="mb-7" :validateStatus="errorState.name">
        <a-input v-model:value="formState.name" class="form-input" placeholder="Name">
          <template #prefix>
            <img :src="prefix_name" v-if="errorState.name === 'success'" />
            <img :src="prefix_name_fail" v-else />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item name="email" class="mb-7" :validateStatus="errorState.email">
        <a-input v-model:value="formState.email" class="form-input" placeholder="Email">
          <template #prefix>
            <img :src="prefix_email" v-if="errorState.email === 'success'" />
            <img :src="prefix_email_fail" v-else />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item name="password" class="mb-3" :validateStatus="errorState.password">
        <a-input-password v-model:value="formState.password" class="form-input" placeholder="Password">
          <template #prefix>
            <img :src="prefix_password" v-if="errorState.password === 'success'" />
            <img :src="prefix_password_fail" v-else />
          </template>
          <!-- <template #suffix>
                                                                <img :src="prefix_password" />
                                                              </template> -->
        </a-input-password>
      </a-form-item>
      <!-- 错误信息展示，常驻 -->

      <a-form-item class="mb-3 error-msg-item">
        <div class="error-msg-box">{{ errorState.errorMsg }}</div>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="registerButtonDisabled" class="form-button">
          <a-spin :indicator="indicator" v-if="isLoading" />
          <span>Continue with email</span>
        </a-button>
      </a-form-item>
    </a-form>
    <VerificationCode v-if="step === 2" @return-previous="step = 1" :count="timer" @re-send="sendEmail"
      :formState="formState" />
    <div class="text-inc-color pb-10">
      Agree to the
      <router-link to="/terms" class="underline">Terms of Service</router-link>,
      <router-link to="/privacy" class="underline">Privacy Policy</router-link>,
      and
      <router-link to="/cookies" class="underline">Cookie Policy</router-link>.
    </div>
  </div>
</template>

<style lang="less" scoped>
.login-form {
  width: 100%;
  max-width: 375px;
}

.ant-form-item {
  width: 100%;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-input {
  overflow: hidden;
}

.form-input, .form-button {
  width: 100%;
  min-width: 100%;
}

@media screen and (any-hover: none) {
  a:hover {
    color: unset;
    text-decoration: underline;
  }
}
</style>
