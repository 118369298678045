<script setup>
import { ref, watchEffect, computed, onMounted, onBeforeUnmount } from 'vue'
import { useWindowSize } from '@vueuse/core'
import login from './login-form.vue'
import signup from './register-form.vue'
import login_welcome from '@/assets/login_welcome.svg'
import tick_icon from '@/assets/tick.svg'
import close_icon from './assets/close_icon.svg'
import { eventTracking } from '@/utils/eventTracking.js'
import { useRoute } from 'vue-router'
import bgImage from '@/assets/login-page-bgimg.svg'
import orangeImg from '@/assets/login-page-orange.svg'
import greenImg from '@/assets/login-page-green.svg'
import charactersImage from '@/assets/login-page-bgimg-characters.webp'
import { tailwindConfig as tailwindSharedConfig } from '@/shared.config.js'

const route = useRoute()
const containerHeight = ref(innerHeight)
const { width: innerWidth } = useWindowSize()
const isPC = computed(() => tailwindSharedConfig.screens.m.max < innerWidth.value)
const tabs = {
  login,
  signup
}

//监听当前路由变化，变化就切到相对应的组件
const currentTab = ref('login');

watchEffect(
  () => {
    currentTab.value = route.path.replace("/", "")
  }
)

function handleResize() {
  containerHeight.value = innerHeight
}

const infoList = [
  {
    id: 1,
    content: "AI Background Remover",
  },
  {
    id: 2,
    content: "Background Blur",
  },
  {
    id: 3,
    content: "Photo Filters and Effects",
  },
  {
    id: 4,
    content: "Photo Animation Effects",
  },
  {
    id: 5,
    content: "Image Compressor",
  },
  {
    id: 6,
    content: "Image Resizer",
  },
  {
    id: 7,
    content: "Sugar Comments",
  },
]

onMounted(() => {
  eventTracking('booltool_signup_view')

  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<template>
  <div class="flex login-page" :style="{minHeight: `${containerHeight - .01}px`}">
    <div v-if="isPC" class="left-box flex-1 flex justify-center align-center">
      <div class="login-page-bgimg">
        <img :src="bgImage" class="login-page-bgimg-bg" />
        <img :src="orangeImg" class="login-page-orange-img" alt="">
        <img :src="greenImg" class="login-page-green-img" alt="">
        <img :src="charactersImage" class="login-page-bgimg-characters" />
      </div>

      <div class="left-info p-8">
        <div class="welcome-box">
          <img :src="login_welcome" />
          <h1 class="font-medium">One-stop solution for your visual creation and content writing</h1>
        </div>

        <div class="tools-wrap-title marginL">Sign up for Premium AI tools free trial, no credit card required</div>
        <div class="welcome-text-wrap">
          <!-- <div class="tools-wrap-title">7 permanently free AI tools:</div> -->
          <div v-for="item in infoList" :key="item.id" class="flex justify-start align-center mt-5 text-base">
            <img :src="tick_icon" class="mr-3.5" />
            <p>{{ item.content }}</p>
          </div>
        </div>

      </div>
    </div>
    <component :is="tabs[currentTab]" style="padding: 60px 24px 0" class="relative right-box flex-1 flex justify-center items-center">
      <!-- <button v-if="isPhone" class="close-button" @click="router.back">
        <img :src="close_icon" width="18"/>
      </button> -->
    </component>
  </div>
</template>

<style lang="less" scoped>
.right-box {
  min-width: 390px;
  flex-direction: column;
}

.left-box {
  overflow: hidden;
  min-width: 565px;
  position: relative;
}

.left-info {
  color: white !important;
  height: fit-content;
  margin: auto;
  z-index: 9;
}

.login-page-bgimg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  min-height: fit-content;
  width: 100%;

  .login-page-bgimg-bg {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  .login-page-orange-img {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 550px;
    width: 15vw;
    min-width: 162px;
  }

  .login-page-green-img {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 205px;
    width: 13vw;
    min-width: 141px;
  }

  .login-page-bgimg-characters {
    min-width: 390px;
    max-width: 521px;
    position: absolute;
    width: 35vw;
    right: 0;
    bottom: 0;
  }
}

.welcome-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;

  img {
    width: 38px;
    height: 38px;
  }

  h1 {
    font-size: 38px;
    line-height: 38px;
    color: white;
    text-align: left;
    margin-left: 12px;
  }
}

.welcome-text-wrap {
  margin-left: 50px;
  margin-top: 47px;
}

.tools-wrap-title {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  line-height: 53px;
}

.marginL {
  margin-left: 50px;
}

.ml {
  margin-top: 28px;
  margin-left: 50px;
}

.close-button {
  position: absolute;
  top: 22.5px;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
</style>
