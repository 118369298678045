<script setup>
import { reactive, ref, h, watch } from "vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
import GoogleLogin from "./google-login.vue";
import prefix_email from "@/assets/prefix_email.svg";
import prefix_password from "@/assets/prefix_password.svg";
import prefix_email_fail from "@/assets/fail_email.svg";
import prefix_password_fail from "@/assets/fail_password.svg";
import { checkForm } from "./utils.js";
import { useDebounceFn } from "@vueuse/core";
import { useUserStore, useSubscriptionInfo } from "@/store/user.js";
import { useRouter, useRoute } from "vue-router";

const user = useUserStore();
const subscriptionInfo = useSubscriptionInfo()
const router = useRouter();
const route = useRoute()
const formState = reactive({
  email: "",
  password: "",
});

const disabled = ref(true)
watch(formState, () => {
  /* 深层级变更状态所触发的回调 */
  if (formState.email && formState.password) {
    disabled.value = false;
  } else {
    disabled.value = true
  }
})
const isLoading = ref(false)
//报错信息
const errorFiles = reactive({
  email: "success",
  password: "success",
  errorMsg: "",
});

const initState = () => {
  errorFiles.email = "success";
  errorFiles.password = "success";
  errorFiles.errorMsg = "";
};

const setErrorState = (msg) => {
  errorFiles.email = "error";
  errorFiles.password = "error";
  errorFiles.errorMsg = msg;
};

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '20px',
    color: '#fff',
    marginRight: '8px'
  },
  spin: true,
});

const debouncedFn = useDebounceFn(async (value) => {
  //login api
  initState();
  isLoading.value = true
  const res = await user.userLogin({
    username: value.email,
    password: value.password,
  });

  const { code, msg } = res;
  if (code !== 0) {
    isLoading.value = false
    setErrorState(msg);
    return;
  } else {
    // user.userSubscription();
    user.subscribeState();
    await subscriptionInfo.refresh()
    isLoading.value = false
    const redirect = route.query?.redirect
    if (redirect) {
      router.push({ path: route.query.redirect });
    } else {
      router.push("/");
    }
  }
}, 500);

const onFinish = (values) => {

  //先检查values是否都存在并且合法,登录状态下不验证参数是否合法
  checkForm(values, true)
    .then(() => {
      initState();
      debouncedFn(values);
    })
    .catch((err) => {
      const errorList = err.errorFiles;
      errorFiles.errorMsg = err.message;
      errorFiles.email = errorList.includes("email") ? "error" : "success";
      errorFiles.password = errorList.includes("password")
        ? "error"
        : "success";
    });
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
</script>

<template>
  <div>
    <slot></slot>
    <a-form :model="formState" class="login-form" name="basic" autocomplete="off" @finish="onFinish"
      @finishFailed="onFinishFailed">
      <a-form-item class="mb-3">
        <h1 class="text-black text-2xl font-medium">Log in</h1>
      </a-form-item>
      <a-form-item class="mb-4">
        <span class="text-gray-400">Don't have an account? </span>
        <router-link to="/signup" class="text-purple-500">Sign up for free</router-link>
      </a-form-item>
      <a-form-item class="mb-6">
        <GoogleLogin />
      </a-form-item>
      <a-form-item name="email" class="mb-7" :validateStatus="errorFiles.email">
        <a-input v-model:value="formState.email" class="form-input" placeholder="Email">
          <template #prefix>
            <img :src="prefix_email" v-if="errorFiles.email === 'success'" />
            <img :src="prefix_email_fail" v-else />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item name="password" class="mb-3" :validateStatus="errorFiles.password">
        <a-input-password v-model:value="formState.password" class="form-input" placeholder="Password">
          <template #prefix>
            <img :src="prefix_password" v-if="errorFiles.password === 'success'" />
            <img :src="prefix_password_fail" v-else />
          </template>
          <!-- <template #suffix>
                                                      <img :src="prefix_password" />
                                                    </template> -->
        </a-input-password>
      </a-form-item>

      <!-- 错误信息展示，常驻 -->
      <a-form-item class="mb-3">
        <div class="error-msg-box">{{ errorFiles.errorMsg }}</div>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="disabled" class="form-button">
          <a-spin :indicator="indicator" v-if="isLoading" />
          <span> Continue with
            email</span></a-button>
      </a-form-item>
      <a-form-item class="forgot-password">
        <router-link :to="{ path: '/resetPwd', query: { step: '1', type: 'forgot' } }"
          class="underline text-inc-color">Forgot password?</router-link>
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang="less" scoped>
.login-form {
  width: 100%;
  max-width: 375px;
}

.error-msg-box {
  max-width: 100%;
  color: #f54a45;
}

:global(.login-form .ant-form-item-control-input-content) {
  text-align: center;
  // display: flex;
  // justify-content: center;
}

:global(.login-form .ant-input-prefix) {
  margin-left: 10px;
  margin-right: 13px;
}

:global(.login-form .ant-input-suffix) {
  width: 17px;
  margin-left: 10px;
  margin-right: 13px;
}

.form-input {
  width: 100%;
  min-width: 100%;
  height: 54px;
  border: 1px solid #d0d0d0;
  border-radius: 41px;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
}
</style>
