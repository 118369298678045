<script setup>
import { ref, watch } from "vue";
import CodeInput from "./code-input.vue";
import returnPrevious from "@/assets/return_previous.svg";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { useUserStore } from "@/store/user.js";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps({
  count: Number,
  formState: Object,
});

const completed = ref(false);
const borderColor = ref("#D0D0D0");
const errorMsg = ref("");
const code = ref("");
const codeButtonDisabled = ref(true);
const btnLoading = ref(false);

watch(code, () => {
  if (code.value.length !== 4) {
    codeButtonDisabled.value = true;
  } else {
    codeButtonDisabled.value = false;
  }
})


const user = useUserStore();
const handleLogin = async () => {
  btnLoading.value = true;
  if (code.value.length !== 4) {
    borderColor.value = "red";
    errorMsg.value = "Please enter code";
    return;
  }
  //login api post
  const params = {
    verifyCode: code.value,
    username: props.formState.name,
    email: props.formState.email,
    password: props.formState.password,
  };
  const result = await user.register(params);
  const { msg } = result;
  if (result.code !== 0) {
    borderColor.value = "red";
    errorMsg.value = msg;
  } else {
    // eslint-disable-next-line no-undef
    gr('track', 'conversion', { email: props.formState.email })
    window.fpr("referral", { email: props.formState.email })
    // user.userSubscription();
    user.subscribeState();
    const redirect = route.query?.redirect
    if (redirect) {
      router.push({ path: route.query.redirect });
    } else {
      router.push("/");
    }
  }
  btnLoading.value = false;
};

const handleChange = (val) => {
  if (val.length === 4) {
    borderColor.value = "#D0D0D0";
    errorMsg.value = "";
    code.value = val;
  }
};
</script>

<template>
  <a-form class="login-form w-5/12 flex flex-1 justify-center items-center flex-col">
    <a-form-item class="mb-4">
      <h1 class="text-black text-2xl font-medium">Verification code</h1>
    </a-form-item>
    <a-form-item class="mb-52">
      <div class="text-gray-400">
        We just sent you a temporary sign up code. Please check your inbox and
        paste the sign up code below.
      </div>
    </a-form-item>
    <a-form-item class="mb-3">
      <CodeInput @complete="(e) => (completed = e)" @change="handleChange" :fields="4" :fieldWidth="54" :fieldHeight="54"
        :required="false" :borderColor="borderColor" />
    </a-form-item>
    <a-form-item class="mb-3">
      <div class="error-msg-box">{{ errorMsg }}</div>
    </a-form-item>
    <a-form-item class="mb-5">
      <button class="customize-button" @click.stop="$emit('reSend')" :style="{
        color: count > 0 ? '#8F959E' : '#060606',
        cursor: count > 0 ? 'not-allowed' : 'pointer'
      }">
        Resend code {{ count > 0 ? `( ${count}S )` : "" }}
      </button>
    </a-form-item>
    <a-form-item class="mb-7">
      <a-button type="primary" class="text-white form-button flex justify-center items-center" @click="handleLogin"
        :disabled="codeButtonDisabled">
        <div class="flex items-center">
          <LoadingOutlined :style="{ marginRight: '15px' }" v-if="btnLoading" />
          <p>Create Boolv Account</p>
        </div>
      </a-button>
    </a-form-item>
    <a-form-item class="mb-3">
      <div class="return-Previous rounded-full flex items-center justify-center cursor-pointer"
        @click="$emit('returnPrevious')">
        <img :src="returnPrevious" />
      </div>
    </a-form-item>
  </a-form>
</template>

<style lang="less" scoped>
.return-Previous {
  width: 48px;
  height: 48px;
  border: 1px solid #d0d0d0;
  margin: 0 auto;

  &:hover {
    background: #dee0e3;
  }
}

.mb-52 {
  margin-bottom: 50px;
}

.customize-button {
  width: 375px;
  height: 54px;
  border-radius: 36px;
  border: 1px solid #D0D0D0;
}
</style>
